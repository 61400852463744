import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginData, LoginInterface } from './login.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements LoginInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  authentincate(param: LoginData) {
    var data = 'grant_type=password&username=' + param.username + '&password=' + encodeURIComponent(param.password) + '&company=' + param.company;
    return this._http.post(environment.endpoint.server + '/token', data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }).pipe(catchError(this.errors$.handleNetworkError));
  }
}