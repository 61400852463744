import { Injectable } from '@angular/core';
import { Entity, Enum } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';

import { DriverManagement, DriverManagementInterface } from './driver-management.model';


import cloneDeep from 'lodash/cloneDeep';
import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
    providedIn: 'root'
})
export class DriverManagementService implements DriverManagementInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }

    header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }

    create(data: Entity<DriverManagement>): Observable<any> {
        return this._http.put<DriverManagement>(
            e.endpoint.fleet.drivermanagement,
            data.value,
            {
                headers: this.header
            }).pipe(catchError(this.errors$.handleNetworkError));
    }

    save: (data: Entity<DriverManagement>) => Observable<any>;
    delete: (data: any) => Observable<any>;
    getEntity: (key: any) => Observable<DriverManagement>;

    private listRetrieved: BehaviorSubject<DriverManagement[]> = new BehaviorSubject<DriverManagement[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();
    getList(): Observable<DriverManagement[]> {
        return this._http.get<DriverManagement[]>(e.endpoint.fleet.drivermanagement, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    notifyRetrieved(data: DriverManagement[]): void {
        this.listRetrieved.next(data);
    }

    getLanguageList(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.fleet.masterDataLanguage + '/0', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    };
    getRoleList(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.fleet.drivermanagement + '/roles', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    };
}