import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity, Enum } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as e } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { DeviceGroup, DeviceGroupInterface } from './device-group.module';

@Injectable({
  providedIn: 'root'
})
export class DeviceGroupService implements DeviceGroupInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }
  private listRetrieved: BehaviorSubject<DeviceGroup[]> = new BehaviorSubject<DeviceGroup[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();

  save: (data: Entity<DeviceGroup>) => Observable<any>;
      getEntity(param: any): Observable<DeviceGroup> {
        return this._http.get<DeviceGroup>(e.endpoint.products.deviceProductGroup + "/" + param.Id , {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }


  notifyRetrieved(data: DeviceGroup[]): void {
    this.listRetrieved.next(data);
  }

  getList(): Observable<DeviceGroup[]> {
    return this._http.get<DeviceGroup[]>(e.endpoint.products.deviceProductGroup, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  create(data: Entity<DeviceGroup>): Observable<any> {
    return this._http.put<DeviceGroup>(
      e.endpoint.products.deviceProductGroup,
      data.value,
      {
        headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(param: any): Observable<any> {
    this.header['responseType'] = 'text/plain';
    return this._http.delete(e.endpoint.products.deviceProductGroup + "/" + param.Id , {
      headers: this.header,
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewEntity() {
    return this._http.get<DeviceGroup>(e.endpoint.products.deviceProductGroup + '/0', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }  

  getProductList(groupId): Observable<Enum[]> {
      return this._http.get<Enum[]>(e.endpoint.products.product + '/dropdown/0', {
          headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }
 
}


