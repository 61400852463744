import { Injectable } from '@angular/core';
import { Entity, Enum } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';
import { Customer, CustomerInterface } from './customer.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerService implements CustomerInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }
    header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }
    private listRetrieved: BehaviorSubject<Customer[]> = new BehaviorSubject<Customer[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();
    notifyRetrieved(data: Customer[]): void {
        this.listRetrieved.next(data);
    }

    save: (data: Entity<Customer>) => Observable<any>;

    getEntity(param: any): Observable<Customer> {
        return this._http.get<Customer>(e.endpoint.customers.customer + "/" + param.Id + "/" + param.Position, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    create(data: Entity<Customer>): Observable<any> {
        return this._http.put<Customer>(
            e.endpoint.customers.customer + "/" + data.value.originalId + "/" + data.value.originalPos,
            data.value,
            {
                headers: this.header
            }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getList(param): Observable<Customer[]> {
        return this._http.put<Customer[]>(e.endpoint.customers.customer + '/filter/', param, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    delete(param: any): Observable<any> {
        this.header['responseType'] = 'text/plain';
        return this._http.delete(e.endpoint.customers.customer + '/' + param.Id + "/" + param.Position, {
            headers: this.header,
            responseType: "text"
        },).pipe(catchError(this.errors$.handleNetworkError));
    }

    getNewEntity(): Observable<Customer> {
        return this._http.get<Customer>(e.endpoint.customers.customer + '/newcustomer', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }


    getBillingCustomers(param): Observable<Customer[]> {
        return this._http.put<Customer[]>(e.endpoint.customers.customer + '/billingcustomer/', param, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getPaymentTermList(companyId): Observable<Enum[]> {
        if (companyId == null) companyId = 0;
        return this._http.get<Enum[]>(e.endpoint.paymentTerms + '/paymentterms/' + companyId + '/1', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getSpecialPriceGroupList(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.customers.specialPriceGroup + '/1', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getProductList(groupId): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.products.product + '/withgroupspecialprices/' + groupId, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getProductDropdown(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.products.product + '/dropdown/0', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getLastDeliveryLocationById(customerId, position): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.unloadPoint + "/lastDeliveryLocation/" + customerId + "/" + position, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getBillQuantityTypeList(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.customers.customer + "/GetBillQuantityTypeList", {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }


    getAutoEmailOptionsList(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.customers.customer + "/GetAutoEmailOptionsList", {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getSignatureSettingsOptionList(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.customers.customer + "/SignatureSettingsOptionList", {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getProductOptions(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.customers.customer + "/ProductOptions", {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getPrintOptions(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.customers.customer + "/PrintOptions", {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getStrappingChartProfile(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.strappingChartProfiles + "/list", {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getDippingType(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.unloadPoint + "/getUnloadPointDippingTypes", {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

}