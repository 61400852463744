import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as e } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { StrappingChart, StrappingChartInterface } from './strapping-chart.model';

@Injectable({
  providedIn: 'root'
})
export class StrappingChartService implements StrappingChartInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }
  private listRetrieved: BehaviorSubject<StrappingChart[]> = new BehaviorSubject<StrappingChart[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();

  save: (data: Entity<StrappingChart>) => Observable<any>;

  notifyRetrieved(data: StrappingChart[]): void {
    this.listRetrieved.next(data);
  }

  getList(): Observable<StrappingChart[]> {
    return this._http.get<StrappingChart[]>(e.endpoint.strappingChartProfiles+ "/list/", {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }
  
  getEntity(param: any): Observable<StrappingChart> {
    return this._http.get<StrappingChart>(e.endpoint.strappingChartProfiles + '/' + param.Id, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  create(data: Entity<StrappingChart>): Observable<any> {
    return this._http.put<StrappingChart>(
      e.endpoint.strappingChartProfiles,
      data.value,
      {
        headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(param: any): Observable<any> {
    this.header['responseType'] = 'text/plain';
    return this._http.delete(e.endpoint.strappingChartProfiles + "/" + param.Id, {
      headers: this.header,
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewEntity() {
    return this._http.get<StrappingChart>(e.endpoint.strappingChartProfiles + '/newchartprofile', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getOrientation() {
    return this._http.get<StrappingChart>(e.endpoint.strappingChartProfiles + '/orientation', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }
  getShape() {
    return this._http.get<StrappingChart>(e.endpoint.strappingChartProfiles + '/shape', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

}
