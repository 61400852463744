import { Injectable } from '@angular/core';
import { Entity, Enum } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';

import { LoadPoint, LoadPointInterface, LoadPointTank } from './load-point.model';


import cloneDeep from 'lodash/cloneDeep';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { Product } from '@products/products.model';

@Injectable({
    providedIn: 'root'
})
export class LoadPointService implements LoadPointInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }
    header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }
    private listRetrieved: BehaviorSubject<LoadPoint[]> = new BehaviorSubject<LoadPoint[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();
    notifyRetrieved(data: LoadPoint[]): void {
        this.listRetrieved.next(data);
    }

    save: (data: Entity<LoadPoint>) => Observable<any>;
    getEntity: (key: any) => Observable<LoadPoint>;

    create(data: Entity<LoadPoint>): Observable<any> {
        return this._http.put<LoadPoint>(
            e.endpoint.loadPoint,
            data.value,
            {
                headers: this.header
            }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getList(): Observable<LoadPoint[]> {
        return this._http.get<LoadPoint[]>(e.endpoint.loadPoint, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    delete(id: any): Observable<any> {
        this.header['responseType'] = 'text/plain';
        return this._http.delete(e.endpoint.loadPoint + '/' + id, {
            headers: this.header,
            responseType: "text"
        },).pipe(catchError(this.errors$.handleNetworkError));
    }

    getProductList(arg): Observable<any[]> {
        return this._http.put<Product[]>(e.endpoint.products.product + '/filter/', arg, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getloadLocationGroup(): Observable<any[]>{
        return this._http.get<Enum[]>(e.endpoint.loadPoint + '/loadlocationgroup',{
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getCountryList(): Observable<Enum[]> {
        return this._http.get<Enum[]>(e.endpoint.masterData+'/countries/0', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getLoadPointDetails (id: any): Observable<any>{
        return this._http.get<any>(e.endpoint.loadPoint + '/'+ id,{
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
}