import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { PriceGroup, PriceGroupInterface } from './price-group.model';
import { PriceGroupService } from './price-group.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IsUndefinedOrNull, dxDataGridHeightAdjustment } from '@app/common_helper';


@Component({
  selector: 'facnm-price-group',
  templateUrl: './price-group.component.html',
  styleUrls: ['./price-group.component.scss'],
  providers: [
    {
      provide: PriceGroupInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new PriceGroupService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})

export class PriceGroupComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  entityName = 'Price Groups'
  entityNameNotification = 'Price Group';
  gridDataSource: any;
  entityData: Entity<PriceGroup>;
  collapseColor = 'green';
  isAddForm = 0;
  newEntityData: PriceGroup;
  productList: any;
  moneySign: any;
  priceGroupGridErrorMsg = '';

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('entityForm') entityForm: NgForm;

  private dataGridPriceGroupItems: DxDataGridComponent;
  @ViewChild('dataGridPriceGroupItems', { static: false }) set dataGridSPContent(content: DxDataGridComponent) {
    if (content) {
      this.dataGridPriceGroupItems = content;
    }
  }

  constructor(
    private pricegroup$: PriceGroupInterface,
    private app$: AppService,
    private errors$: ErrorsService,
    private actions$: ActionsService,
  ) { }

  ngOnInit() {
    this.app$.setScreenTitle(this.entityName);
    this.actions();
    forkJoin({
      getSystemPreferences: this.pricegroup$.getSystemPreferences(),    
      pricegroupList: this.pricegroup$.getList(0),
      newEntity: this.pricegroup$.getNewEntity(),
      productList: this.pricegroup$.getProductList(),
    }).subscribe(
      res => {
        this.pricegroup$.notifyRetrieved(res['pricegroupList']);
        this.newEntityData = res['newEntity'];
        this.productList =res['productList'];
        this.moneySign = res['getSystemPreferences'].CurrencyString;
        this.app$.notifyScreenReady();
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }

  actions() {
    this.pricegroup$.ListRetrieved.subscribe(data => {
      if (data) {
        this.gridDataSource = new ArrayStore({
          data: data,
          key: ['Id']
        });
      }
    });

    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  getList() {
    this.pricegroup$.getList(0).subscribe(listWithCreated => {
      this.pricegroup$.notifyRetrieved(listWithCreated);
      this.app$.notifyScreenReady();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }
  
  refreshSpecialPriceGrid() {
    if (this.dataGridPriceGroupItems) {
      this.dataGridPriceGroupItems.instance.refresh();
    }
  }
  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid || (this.entityData.value.Products.length < 1)) {
      setTimeout(() => { this.app$.notifyScreenReady(); })
      return;
    }
    if (IsUndefinedOrNull(this.entityData.value.Id) || this.entityData.value.Id <= 0) {
      this.entityData.value.Id = -1;
    }
    this.pricegroup$.create(this.entityData).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deSelectEntity();
        this.getList();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  private deleteConfirm() {
    let param = { Id: this.entityData.value.Id};
    this.pricegroup$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.getList();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onContentReady(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Price Groups Found";
  }

  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData) {
      this.entityData = new Entity();
      this.entityData.key = undefined;
    }
    if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity($clikedRow.key);
    }
  }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = undefined;
  }

  private selectEntity(key: any) {
    this.app$.notifyScreenBusy();
    this.isAddForm = 0;
    this.collapseColor = 'green';
    this.pricegroup$.getEntity(key).subscribe((res: any) => {
      this.app$.notifyScreenReady();
      if (!res.Products)
        res.Products = [];
      this.entityData = new Entity();
      this.entityData.key = key;
      this.entityData.value = cloneDeep(res);
      this.refreshPriceGroupItemsGrid();
    }, (errorGetList) => {
    this.cancel();
    this.errors$.handleServerErrors(errorGetList)
  });
  }
  refreshGrid() {
    this.dataGrid.instance.refresh();
  }
  refreshPriceGroupItemsGrid() {
    if (this.dataGridPriceGroupItems) {
      this.dataGridPriceGroupItems.instance.refresh();
      this.priceGroupGridErrorMsg = "";
    }
  }

  onPriceGroupItemsGridRowInserting(e) {
    this.priceGroupGridErrorMsg = "";
    if (!IsUndefinedOrNull(e.data)) {
      if (IsUndefinedOrNull(e.data.ProductId)) {

        this.priceGroupGridErrorMsg = "Please select product.";
        e.cancel = true;
        return;
      }
    }
    else {
      this.priceGroupGridErrorMsg = "Please enter all values for new record.";
      e.cancel = true;
      return;
    }
  }

  onPriceGroupItemsGridRowInserted(e:any) {
    this.pricegroup$.getProductDetails(e.data.ProductId).subscribe((data:any) => {
      this.entityData.value.Products.forEach((p: any) => {

        if (p.ProductId == data.Id) {
            p.QuantityUnit =  (IsUndefinedOrNull(data.QuantityUnit) ? '' : data.QuantityUnit);
            p.PricePer = ((IsUndefinedOrNull(data.PricePer) || (data.PricePer == 1)) ? '' : data.PricePer);
        }      
      });
      this.refreshPriceGroupItemsGrid();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  };

  private reset() {
    this.entityData.restore();
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
  }

  private addNewEntity() {
    this.priceGroupGridErrorMsg = "";
    this.app$.setScreenTitle('New ' + this.entityNameNotification);
    this.entityData = new Entity();
    this.entityData.value = cloneDeep(this.newEntityData);
    this.entityData.created = true;
    this.collapseColor = 'yellow';
    this.isAddForm = 1;
  }

  onContentReadyPriceGroupItems(e: any) {
    this.dataGridPriceGroupItems.noDataText = this.dataGridPriceGroupItems.instance.totalCount() ? '' : "No Data";
    this.priceGroupGridErrorMsg = "";
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
 
}
