import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity, Enum } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdditionalFields, AdditionalFieldsInterface } from './additional-fields.model';
import { environment as e } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdditionalFieldsService implements AdditionalFieldsInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }
  private listRetrieved: BehaviorSubject<AdditionalFields[]> = new BehaviorSubject<AdditionalFields[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();
  notifyRetrieved(data: AdditionalFields[]): void {
    this.listRetrieved.next(data);
  }

  save: (data: Entity<AdditionalFields>) => Observable<any>;

  create(data: Entity<AdditionalFields>): Observable<any> {
    return this._http.put<AdditionalFields>(
      e.endpoint.fleet.additionalFields + "/additionalField",
      data.value,
      {
        headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getList(): Observable<AdditionalFields[]> {
    return this._http.get<AdditionalFields[]>(e.endpoint.fleet.additionalFields + '/list', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewEntity(): Observable<AdditionalFields> {
    return this._http.get<AdditionalFields>(e.endpoint.fleet.additionalFields + '/new', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(key: any): Observable<any> {
    this.header['responseType'] = 'text/plain';
    return this._http.delete(e.endpoint.fleet.additionalFields + "/" + key.FieldType + "/" + key.FieldLocation, {
      headers: this.header,
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

  getEntity(param) {
    return this._http.get<AdditionalFields>(e.endpoint.fleet.additionalFields + '/' + param.FieldType + '/' + param.FieldLocation, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getDataTypeList() {
    return this._http.get<Enum[]>(e.endpoint.fleet.additionalFields + '/datatypes', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getFieldLocations() {
    return this._http.get<Enum[]>(e.endpoint.fleet.additionalFields + '/fieldtypes', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getViewModeTypes() {
    return this._http.get<Enum[]>(e.endpoint.fleet.additionalFields + '/viewmodetypes', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getTimeLogTypeList() {
    return this._http.get<Enum[]>(e.endpoint.fleet.timeLog, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getLoadLocationTypes() {
    return this._http.get<Enum[]>(e.endpoint.fleet.additionalFields + '/loadLocationTypes', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

}


