import { Injectable } from '@angular/core';
import { Config, TranspacConfigurationInterface } from './transpac-configuration.model';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment as e } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranspacConfigurationService implements TranspacConfigurationInterface {

  header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  getConfigurationGroup(): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.fleet.configuration + "/configurationgroup", {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getDeviceList(): Observable<any[]> {
    return this._http.get<any[]>(e.endpoint.fleet.devices, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getConfigurationValues(param: any): Observable<Config[]> {
    return this._http.get<Config[]>(e.endpoint.fleet.configuration + "/" + param.groupId + "/" + param.deviceId + "/" + param.driverId
      + "/" + param.configGroupId + '?noCache=' + new Date().getTime(), {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getConfigurationValuesByDeviceId(deviceId: any): Observable<Config[]> {
    return this._http.get<Config[]>(e.endpoint.fleet.configuration + "/device/" + deviceId, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getConfigurationValuesByGroupSpecific(groupId: any): Observable<Config[]> {
    return this._http.get<Config[]>(e.endpoint.fleet.configuration + "/groupspecific/" + groupId, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getConfigurationValuesBySearch(param: any): Observable<Config[]> {
    return this._http.get<Config[]>(e.endpoint.fleet.configuration + "/search/" + param.searchString + "/" + param.configurationType
      + "/" + param.deviceDriverId + "/" + param.configGroupId, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getConfigurationValue(param: any): Observable<Config[]> {
    return this._http.get<Config[]>(e.endpoint.fleet.configuration + "/getconfigvalue/" + param.configId + "/" + param.deviceId + "/" + param.driverId
      + "/" + param.groupId + '?noCache=' + new Date().getTime(), {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  saveConfigurationValue(data: any): Observable<any> {
    return this._http.put<any>(
      e.endpoint.fleet.configuration + '/save1/',
      data,
      {
        headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }

  deleteConfigurationValue(param: any): Observable<any> {
    this.header['responseType'] = 'text/plain';
    return this._http.delete(e.endpoint.fleet.configuration + "/" + param.configId + "/" + param.deviceId + "/" + param.driverId
      + "/" + param.groupId + '?noCache=' + new Date().getTime(), {
      headers: this.header,
      responseType: "text"
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  saveModalConfigurationValues(data: any): Observable<any> {
    return this._http.put<any>(
      e.endpoint.fleet.configuration + '/complex/',
      data,
      {
        headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }

}
