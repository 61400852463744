import { Injectable } from '@angular/core';
import { Entity, Enum } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';
import { PriceGroup, PriceGroupInterface } from './price-group.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
  providedIn: 'root'
})
export class PriceGroupService implements PriceGroupInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }
  header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }

  private listRetrieved: BehaviorSubject<PriceGroup[]> = new BehaviorSubject<PriceGroup[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();
  notifyRetrieved(data: PriceGroup[]): void {
    this.listRetrieved.next(data);
  }

  save: (data: Entity<PriceGroup>) => Observable<any>;

  create(data: Entity<PriceGroup>): Observable<any> {
    return this._http.put<PriceGroup>(e.endpoint.products.specialPriceGroup+ "/products",
      data.value,
      {
        headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }
  delete(param: any): Observable<any> {
    this.header['responseType'] = 'text/plain';
    return this._http.delete(e.endpoint.products.specialPriceGroup + "/products" + "/" + param.Id, {
      headers: this.header,
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

  getProductList(): Observable<Enum[]> {
    return this._http.get<Enum[]>(e.endpoint.products.product + "/dropdown/1", {
        headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }
  getProductDetails(productid): Observable<Enum[]> {
    return this._http.get<Enum[]>(e.endpoint.products.product + "/" + productid, {
        headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
}
  getEntity(param: any): Observable<PriceGroup> {
    return this._http.get<PriceGroup>(e.endpoint.products.specialPriceGroup + "/products" + "/" + param.Id, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }
  getList(lookup: any): Observable<PriceGroup[]> {
    return this._http.get<PriceGroup[]>(e.endpoint.products.specialPriceGroup + "/products", {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewEntity(): Observable<PriceGroup> {
    return this._http.get<PriceGroup>(e.endpoint.products.specialPriceGroup + "/products" + "/newpricegroup", {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }
  getSystemPreferences(): Observable<any> {
    return this._http.get<any>(e.endpoint.fleet.configuration+"/systempreferences",  {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

}
