import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';
import { ModulesInterface, Modules } from './modules.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
    providedIn: 'root'
})
export class ModulesService implements ModulesInterface {
    constructor(private _http: HttpClient, private errors$: ErrorsService) { }    
    save(data: Modules): Observable<any> {
        return this._http.put<Modules>(
            e.endpoint.settings.modules + '/save',
            data,
            {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
                }
            }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getList(): Observable<Modules[]> {
        return this._http.get<Modules[]>(e.endpoint.settings.modules, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
            }
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

}