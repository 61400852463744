import { Injectable } from '@angular/core';
import { Entity } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';
import { DataArea, DataAreaInterface } from './data-area.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
    providedIn: 'root'
})
export class DataAreaService implements DataAreaInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }
    header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }
    private listRetrieved: BehaviorSubject<DataArea[]> = new BehaviorSubject<DataArea[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();
    notifyRetrieved(data: DataArea[]): void {
        this.listRetrieved.next(data);
    }

    save: (data: Entity<DataArea>) => Observable<any>;
    getEntity: (key: any) => Observable<DataArea>;

    create(data: Entity<DataArea>): Observable<any> {
        return this._http.put<DataArea>(
            e.endpoint.products.dataArea+"/productgroup/",
            data.value,
            {
                headers: this.header
            }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getList(lookup): Observable<DataArea[]> {
        return this._http.get<DataArea[]>(e.endpoint.products.dataArea + '/list/'+lookup, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    delete(param: any): Observable<any> {
        this.header['responseType'] = 'text/plain';
        return this._http.delete(e.endpoint.products.dataArea + '/' + param.Id, {
            headers: this.header,
            responseType: "text"
        },).pipe(catchError(this.errors$.handleNetworkError));
    }

    getProductGroupList() {
        return this._http.get<any[]>(e.endpoint.products.productGroup, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getDataAreaByID(Key) {
        return this._http.get<DataArea>(e.endpoint.products.dataArea + "/productgroup/" + Key.Id, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    saveDataArea(dataArea) {
        return this._http.put<any>(e.endpoint.products.dataArea + "/productgroup/", dataArea, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

}