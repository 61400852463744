import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User, UserInterface } from './users.model';
import { environment as e } from 'src/environments/environment';
import { Entity, Enum } from '@app/app.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService implements UserInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }

  getList(): Observable<User[]> {
    return this._http.get<User[]>(e.endpoint.settings.user, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  create(data: Entity<User>): Observable<any> {
    return this._http.put<User>(
      e.endpoint.settings.user,
      data.value,
      {
        headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }

  save: (data: Entity<User>) => Observable<any>;
  delete: (data: any) => Observable<any>;
  getEntity: (key: any) => Observable<User>;

  private listRetrieved: BehaviorSubject<[User]> = new BehaviorSubject<[User]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();

  notifyRetrieved(data): void {
    this.listRetrieved.next(data);
  }

  getFleetList(): Observable<Enum[]> {
    return this._http.get<Enum[]>(e.endpoint.api + 'fleet', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  };
  getCompanyList(): Observable<Enum[]> {
    return this._http.get<Enum[]>(e.endpoint.company, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  };

  getUserDetailById(userId: any): Observable<User> {
    return this._http.get<User>(e.endpoint.settings.user + '/' + userId, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  };
  getRoleList(isSysAdmin): Observable<Enum[]> {
    return this._http.get<Enum[]>(e.endpoint.settings.user + '/role/'+ isSysAdmin, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  };
}




