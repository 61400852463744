import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { PrintTemplate, PrintTemplatesInterface, PrintTemplateEditor, PrintTemplateTest, SaleTemplateTest, LoadingTemplateTest, SafetyCheckTemplateTest, SaleProtocolTemplateTest, AssessmentTemplateTest, TransportDocumentTemplateTest, PrintTemplateLog, DeliveryAuthorizationDocumentTemplateTest } from './print-templates.model';


import { ErrorsService } from '@app/_shared/errors/errors.service';
import { PrintTemplatesService } from './print-templates.service';
import { PrintTemplatesEditService } from './print-templates-edit.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import ArrayStore from 'devextreme/data/array_store';
import { Entity, Enum } from '@app/app.model';

import { SharedService } from '@app/_shared/shared.service';
import { Company, Device, ServerError } from '@app/_shared/shared.model';

import { find, cloneDeep } from 'lodash';

import { DxDataGridModule, DxDropDownBoxComponent } from 'devextreme-angular';
import { DateRange } from '@pdi/platform-angular-template';


@Component({
    selector: 'facnm-print-templates-edit',
    templateUrl: './print-templates-edit.component.html',
    styleUrls: ['./print-templates-edit.component.scss'],
    providers: [
        {
            provide: PrintTemplatesInterface,
            useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
                return new PrintTemplatesService(httpClient, errors$);
            },
            deps: [HttpClient, ErrorsService]
        },
        PrintTemplatesEditService
    ]
})
export class PrintTemplatesEditComponent implements OnInit, AfterViewInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    animation: any;
    shadingColor: any;
    errorsHeight: number;

    @Input() printTemplate: PrintTemplate;
    @Input() companyId: number;
    @Output() onDone = new EventEmitter();

    companies: Company[];
    devices: Device[];
    device: any;
    devicesStore: ArrayStore;
    currentDeviceId: number;
    testDataDeviceId: number;

    printTemplateContent: string;
    printTemplateEditor: PrintTemplateEditor;
    printTemplateContentCopy: string;
    printTemplateContentModified: boolean;

    templateTypes: Enum[];
    templateTypesReduced: Enum[];
    safetyGroups: Enum[];
    safetyGroupsReduced: Enum[];
    isTourSaleProtocol: boolean;

    searchDateFrom_PrintTemplateTestData: Date = new Date();
    searchDateTo_PrintTemplateTestData: Date = new Date();

    printTemplateEditSection: any;

    dataGridTestData: any;
    templateTestEntity: Entity<PrintTemplateTest>;
    testDataStore: ArrayStore;

    dataGridLogData: any;
    logDataStore: ArrayStore;
    okPrintTest: boolean;
    PrintErrors: any[] = [];

    dataGridAttributeData: any;
    printSettingsData:any;
    printSettingsDataCopy:any;
    attributeDataStore: ArrayStore;
    attributeDataSource: ArrayStore;
    isSaveDisabled: boolean = true;
    dateFormat = '';

    enabledRangeFrom: DateRange = {
        from: new Date(this.searchDateTo_PrintTemplateTestData.setDate(this.searchDateFrom_PrintTemplateTestData.getDate()-7)),
    };
    enabledRangeTo: DateRange = {
        to: new Date(this.searchDateFrom_PrintTemplateTestData),
    };

    constructor(private printTemplates$: PrintTemplatesInterface, private printTemplatesEdit$: PrintTemplatesEditService, private errors$: ErrorsService, private app$: AppService, private actions$: ActionsService, private shared$: SharedService) {
        this.searchDateFrom_PrintTemplateTestData.setDate(this.searchDateFrom_PrintTemplateTestData.getDate() - 7);
        this.searchDateTo_PrintTemplateTestData = new Date();
        this.currentDeviceId = 0;

        this.printTemplateEditSection = 'EDIT';

        this.animation = app$.getAnimationSettings();
        this.shadingColor = app$.getShadingColor();

        this.printTemplateEditor = new PrintTemplateEditor();

        app$.ScreenReady.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.errorsHeight = 0);
        
        this.dateFormat = app$.dateFormat;
        errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe(errors => {
            this.errorsHeight = errors == null || errors == false ? 0 : 70;
            this.resizeScreenContent();
        });

        this.printTemplateContentSubject.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe(content => {
                this.printTemplatesEdit$.updateVerticalLines(content);
                this.editorView.codeMirror.refresh();
            });

        

        this.printTemplates$.TestDataRetrieved.pipe(
            takeUntil(this.unsubscribe$),
            map(data => {

                if (data) {

                    let templateTestEntity = [];
                    let key;

                    if (this.isSaleTemplate() || this.isCustomerDocumentTemplate()) {

                        key = ['Id'];

                        let testDataTemplateTypes: number[] = [];

                        data.forEach((s: SaleTemplateTest, i) => {

                            templateTestEntity.push(s);

                            if (!testDataTemplateTypes.includes(s.TemplateTypeId)) {
                                testDataTemplateTypes.push(s.TemplateTypeId);
                            }
                        });

                        this.templateTypesReduced = this.templateTypes.filter(t => testDataTemplateTypes.includes(t.id));
                    }

                    else if (this.isLoadingTemplate()) {

                        key = ['Id', 'TourId'];

                        data.forEach((s: LoadingTemplateTest, i) => {

                            templateTestEntity.push(s);
                        });
                    }

                    else if (this.isSafetyCheckTemplate()) {

                        let testDataSafetyGroups = [];

                        key = ['Id', 'SessionId'];

                        data.forEach((s: SafetyCheckTemplateTest, i) => {

                            templateTestEntity.push(s);

                            if (!testDataSafetyGroups.includes(s.SafetyGroupId)) {
                                testDataSafetyGroups.push(s.SafetyGroupId);
                            }
                        });

                        this.safetyGroupsReduced = this.safetyGroups.filter(t => testDataSafetyGroups.includes(t.id));
                    }

                    else if (this.isSaleProtocolTemplate()) {

                        key = ['Id'];

                        data.forEach((s: SaleProtocolTemplateTest, i) => {

                            templateTestEntity.push(s);
                        });

                        this.isTourSaleProtocol = data.every((t: SaleProtocolTemplateTest) => t.TourId != null);
                    }

                    else if (this.isAssessmentTemplate()) {

                        key = ['Id', 'SessionId'];

                        data.forEach((s: AssessmentTemplateTest, i) => {

                            templateTestEntity.push(s);
                        });
                    }

                    else if (this.isDeliveryAuthorizationDocumentTemplate()) {

                        key = ['Id', 'SessionId'];

                        data.forEach((s: DeliveryAuthorizationDocumentTemplateTest, i) => {

                            templateTestEntity.push(s);
                        });
                    }

                    return { templateTestEntity, key };
                }
            }))

            .subscribe((testDataMapped) => {

                if (testDataMapped) {

                    this.testDataStore = new ArrayStore({
                        data: testDataMapped.templateTestEntity,
                        key: testDataMapped.key
                    });
                    if (testDataMapped.templateTestEntity.length == 0) {
                        this.dataGridTestData.instance().option('noDataText', 'No test data');
                    }
                    else {

                        this.testDataDeviceId = this.currentDeviceId;

                        this.dataGridTestData.instance().option('noDataText', '');
                    }
                }
            });




        this.printTemplates$.LogDataRetrieved.pipe(takeUntil(this.unsubscribe$))

            .subscribe((logsData) => {
               
                if (logsData) {

                    this.logDataStore = new ArrayStore({
                        data: logsData,
                        key: ['LogDate']
                    });
                    if (logsData.length == 0) {
                        this.dataGridLogData.instance().option('noDataText', 'No log data');
                    }
                    else {
                        this.dataGridLogData.instance().option('noDataText', '');
                    }
                }
            });

            
        this.printTemplates$.AttributeDataRetrieved.pipe(takeUntil(this.unsubscribe$))
        .subscribe((printSettings) => {
            if(printSettings){
             this.printSettingsData = printSettings;
             this.printSettingsDataCopy = printSettings;
             if (this.printSettingsData.KeywordAttributes) {
                this.attributeDataStore = new ArrayStore({
                    data: this.printSettingsData.KeywordAttributes,
                    key: ['Keyword']
                });
                if (this.printSettingsData.KeywordAttributes.length == 0) {
                    this.dataGridAttributeData.instance().option('noDataText', 'No Attribute Found');
                }
                else {
                    this.dataGridAttributeData.instance().option('noDataText', '');
                }
            }
          }
        });

    }


    ngOnInit() {

        forkJoin(

            this.printTemplates$.getKeywords(),
            this.printTemplates$.getLineLength(),
            this.shared$.getCompanyList(),
            this.shared$.getDeviceList(),
            this.printTemplates$.getTemplatesTypes(),
            this.shared$.getSafetyGroups()

        ).subscribe(
            multipleData => {

                this.companies = multipleData[2];
                this.devices = multipleData[3];
                this.templateTypes = multipleData[4];
                this.safetyGroups = multipleData[5];

                this.devicesStore = new ArrayStore({
                    data: this.devices,
                    key: 'Id'
                });

                this.printTemplatesEdit$.defineCodeMirrorMode(multipleData[0]);
                this.printTemplatesEdit$.setVerticalBaseLine(multipleData[1] || 80);

                this.printTemplateEditor.options = this.printTemplatesEdit$.getCodeMirrorOptions();

                this.printTemplates$.DownloadPrinterForm(this.printTemplate, false).subscribe(

                    (okDownloadShadow) => {

                        this.printTemplateContent = okDownloadShadow;
                        this.printTemplateContentCopy = okDownloadShadow;

                        this.printTemplatesEdit$.updateVerticalLines(this.printTemplateContent);

                        this.app$.notifyScreenReady();

                    },

                    (errorDownloadShadow) => {
                        this.errors$.handleServerErrors(errorDownloadShadow);
                    }
                );

            },

            (errorNetwork) => {
                this.errors$.handleServerErrors(errorNetwork);
            }
        );

    }


    @ViewChild('editorView') editorView;
    cursorActivity(e) {

        if (this.editorView)
            this.printTemplateEditor.cursor = this.editorView.codeMirror.getCursor();
    }

    printTemplateContentSubject: Subject<string> = new Subject<string>();
    onChangePrintTemplateContent(contentChanged) {
        if (contentChanged && this.printTemplateContent == this.printTemplateContentCopy) {
            this.printTemplateContentSubject.next(contentChanged);
            this.isSaveDisabled = false;
        }
        else if(this.printSettingsData && (contentChanged == 'Grid'||
            (contentChanged == 'Attribute' && 
            (this.printSettingsData.TopBorder == this.printSettingsDataCopy.TopBorder || 
            this.printSettingsData.BottomBorder == this.printSettingsDataCopy.BottomBorder ||
            this.printSettingsData.PrintPaymentSlip == this.printSettingsDataCopy.PrintPaymentSlip)))) {
                this.isSaveDisabled = false;
        }
    }

    ngAfterViewInit() {
        this.resizeScreenContent();
    }

    getCompanyName() {

        if (this.companyId) {
            let company = find(this.companies, c => c.Id == this.companyId);

            if (company)
                return company.name;
        }

        return '';
    }


    onContentReady_sections(e) {

        e.component.selectItem(0);
    }

    switchSection(e) {

        this.errors$.clearErrors();

        this.printTemplateEditSection = e.itemData.text;

        if (this.printTemplateEditSection == 'LOG') {
            
                this.app$.notifyScreenBusy();
                this.printTemplates$.getLogData(this.printTemplate).subscribe(

                    (okLogData) => {

                        this.printTemplates$.notifyLogDataRetrieved(okLogData);
                        this.app$.notifyScreenReady();
                    },

                    (errorLogData) => {
                        this.errors$.handleServerErrors(errorLogData);
                    }
                );
        }

        if(this.printTemplateEditSection == 'ATTRIBUTES'){
            this.app$.notifyScreenBusy();
            this.printTemplates$.getAttributeData(this.printTemplate).subscribe(
                (success) =>{
                    this.printTemplates$.notifyAttributeData(success);
                    this.app$.notifyScreenReady();
                },
                (error) => {
                    this.errors$.handleServerErrors(error);
                }
            );
        }

        this.resizeScreenContent();

        setTimeout(() => {

            if (this.dataGridTestData && this.templateTestEntity && this.templateTestEntity.key) {

                this.dataGridTestData.instance().selectRows([this.templateTestEntity.key]);
                //this.dataGridTestData.instance().navigateToRow([this.templateTestEntity.key]); //REVIEW buggy, will jump to the last grid page
            }
        }, 1000);
    }


    onRowClick_TestData($clikedRow) {

        this.okPrintTest = undefined;

        if (!this.templateTestEntity)
            this.templateTestEntity = new Entity();

        if (JSON.stringify(this.templateTestEntity.key) === JSON.stringify($clikedRow.key)) {
            this.deselectTestData();
        }
        else {
            this.selectTestData($clikedRow.key);
        }
    }




    onInitialized_TestData(e) {

        this.dataGridTestData = e.component.instance();

        this.resizeScreenContent();
    }



    onInitialized_LogData(e) {

        this.dataGridLogData = e.component.instance();

        this.resizeScreenContent();
    }

    onInitialized_Attribute(e) {
        this.dataGridAttributeData = e.component.instance();

        this.resizeScreenContent();
    }

    calculateLogDataChanges(rowData: PrintTemplateLog) {

        return (rowData.IsLive ? ' Published.  ' : '') +
            (rowData.InsertsNo > 0 ? rowData.InsertsNo + ' Added.  ' : '') +
            (rowData.UpdatesNo > 0 ? rowData.UpdatesNo + ' Updated.  ' : '') +
            (rowData.DeletesNo > 0 ? rowData.DeletesNo + ' Deleted.' : '');
    }


    saveEditedTemplate() {

        this.app$.notifyScreenBusy();

        this.printTemplates$.UploadPrinterForm(new File([this.printTemplateContent], ''), this.printTemplate, this.printSettingsData).subscribe(
            (okUploadShadow) => {

                this.onDone.emit();
                this.actions$.notifyEntityCustomAction({ title: 'Uploaded', message: 'The print template was uploaded to shadow' });
            },

            (errorUploadShadow) => {
                this.errors$.handleServerErrors(errorUploadShadow);
            }
        );
    }

    search() {

        this.okPrintTest = undefined;

        this.deselectTestData();
        this.errors$.clearErrors()

        this.app$.notifyScreenBusy();

        this.printTemplates$.getTestData(this.printTemplate.Id, this.companyId, this.currentDeviceId, this.searchDateFrom_PrintTemplateTestData, this.searchDateTo_PrintTemplateTestData).subscribe(templateTestEntity => {

            this.app$.notifyScreenReady();

            this.printTemplates$.notifyTestDataRetrieved(templateTestEntity.TemplateTestEntity);

        }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
    }


    test() {

        this.okPrintTest = undefined;

        this.errors$.clearErrors()

        this.app$.notifyScreenBusy();

        if (this.isTransportDocumentTemplate() || this.isCollectiveSettlementTemplate() || this.isTourPaperTemplate()) {
            (this.templateTestEntity.value as TransportDocumentTemplateTest).DateFrom = this.searchDateFrom_PrintTemplateTestData;
            (this.templateTestEntity.value as TransportDocumentTemplateTest).DateTo = this.searchDateTo_PrintTemplateTestData;

            this.testDataDeviceId = this.templateTestEntity.key;
        }

        else if (this.isCashBalanceTemplate()) {

            this.testDataDeviceId = this.templateTestEntity.key;
        }

        this.printTemplates$.TestPrinterForm(this.printTemplateContent, this.templateTestEntity.value, this.testDataDeviceId, this.printTemplate.Id).subscribe(
            (okTest: Blob) => {

                this.okPrintTest = true;

                this.app$.notifyScreenReady();

                var pdf = URL.createObjectURL(okTest);

                window.open(pdf, '_blank');

                //saveAs(okTest, 'print-template-test.pdf'); REVIEW popup above may be blocked by the browser
            },

            (errorTest) => {

                this.okPrintTest = false;

                if (errorTest.size && errorTest.type && errorTest.type == "application/json") {

                    const blobReader = new FileReader();

                    blobReader.addEventListener('loadend', (e) => {

                        let blobError = new ServerError();

                        let httpErrorResponseJson = JSON.parse((e.srcElement as any).result);
                        blobError.message = httpErrorResponseJson.Message;
                        this.PrintErrors = httpErrorResponseJson.PrintErrors;

                        this.errors$.handleServerErrors(blobError);
                    });

                    blobReader.readAsText(errorTest);
                }
                else this.errors$.handleServerErrors(errorTest);
            }
        );

    }


    cancelEditTemplate() {
        this.errors$.clearErrors()
        this.onDone.emit();
    }


    getSelectedDeviceName = (device: Device) => device ? device.Name : '';

    @ViewChild('dropdownDevices') dropdownDevices: DxDropDownBoxComponent;
    onDevicesRowClick($event) {

        this.currentDeviceId = $event.key;
        this.dropdownDevices.instance.close();

        if (this.isTransportDocumentTemplate() || this.isTourPaperTemplate() || this.isCollectiveSettlementTemplate() || this.isCashBalanceTemplate()) {

            this.templateTestEntity = new Entity();
            this.templateTestEntity.key = this.currentDeviceId;
        }
    }


    isSaleTemplate = () => [1, 2, 3, 4, 5, 6, 7, 8, 11].includes(this.printTemplate.Id);

    isQuittanceTemplate = () => [9].includes(this.printTemplate.Id);

    isTransportDocumentTemplate = () => [10].includes(this.printTemplate.Id);

    isTourPaperTemplate = () => [14].includes(this.printTemplate.Id);

    isAssessmentTemplate = () => [15].includes(this.printTemplate.Id);

    isSafetyCheckTemplate = () => [16].includes(this.printTemplate.Id);

    isCustomerDocumentTemplate = () => [17].includes(this.printTemplate.Id);

    isLoadingTemplate = () => [18, 19].includes(this.printTemplate.Id);

    isCollectiveSettlementTemplate = () => [50].includes(this.printTemplate.Id);

    isSaleProtocolTemplate = () => [51].includes(this.printTemplate.Id);

    isCashBalanceTemplate = () => [52].includes(this.printTemplate.Id);

    isDeliveryAuthorizationDocumentTemplate = () => [20].includes(this.printTemplate.Id);

    canTestTemplate = () => this.isSaleTemplate() || this.isTransportDocumentTemplate() || this.isTourPaperTemplate() || this.isAssessmentTemplate() || this.isSafetyCheckTemplate() || this.isCustomerDocumentTemplate() || this.isLoadingTemplate() || this.isCollectiveSettlementTemplate() || this.isSaleProtocolTemplate() || this.isCashBalanceTemplate() || this.isDeliveryAuthorizationDocumentTemplate();

    canSearchTemplateData = () => this.isSaleTemplate() || this.isAssessmentTemplate() || this.isSafetyCheckTemplate() || this.isCustomerDocumentTemplate() || this.isLoadingTemplate() || this.isSaleProtocolTemplate() || this.isDeliveryAuthorizationDocumentTemplate();

    canSearchTemplateDataWithDateRange = () => !this.isCashBalanceTemplate();

    isEmptySearchFilter = () => this.currentDeviceId == 0;

    isEmptyTemplateData = () => (!this.templateTestEntity || !this.templateTestEntity.key);



    private selectTestData(testDataKey: any) {

        this.actions$.notifyEntityBusy();

        this.templateTestEntity = new Entity();
        this.templateTestEntity.key = testDataKey;

        this.testDataStore.byKey(testDataKey)
            .then(s => {

                this.templateTestEntity.value = cloneDeep(s);

                this.app$.notifyScreenReady()
            });

    }

    private deselectTestData() {

        if (this.templateTestEntity && this.templateTestEntity.key) {

            this.dataGridTestData.instance().deselectRows(this.templateTestEntity.key);
            this.templateTestEntity.key = undefined;
        }
    }




    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onResize(e): void {
        this.resizeScreenContent();
    }


    formHeight: string;
    @ViewChild('screen', { static: true }) screenElement: ElementRef;
    private resizeScreenContent() {
        let h = this.screenElement.nativeElement.clientHeight;
        let w = this.screenElement.nativeElement.clientWidth;

        setTimeout(()=>{
            this.printTemplateEditor.height = h - 200;
        })

        if (this.dataGridTestData) {

            this.dataGridTestData.instance().option('height', h - 205 - this.errorsHeight);

        }
        if (this.dataGridLogData) {

            this.dataGridLogData.instance().option('height', h - 202 - this.errorsHeight);

        }
        if(this.dataGridAttributeData){
            this.dataGridAttributeData.instance().option('height', h - 205 - this.errorsHeight);
        }
    }
    OnloadSearchDateTo_PrintTemplateTestData(toDateValue) {
        this.enabledRangeFrom = {
            from: new Date(toDateValue)
        };
    }
    OnloadSearchDateFrom_PrintTemplateTestData(fromDateValue) {
        this.enabledRangeTo = {
            to: new Date(fromDateValue)
        };
    }
}
