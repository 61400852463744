import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment as e } from 'src/environments/environment';

import { Enum, Entity } from '@app/app.model';
import { ProductTransformationInterface, ProductTransformation } from './product-transformation.model';

import { ErrorsService } from '@app/_shared/errors/errors.service';


@Injectable({
    providedIn: 'root'
})
export class ProductTransformationService implements ProductTransformationInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }

    create(data: Entity<ProductTransformation>): Observable<any> {

        return this._http.post<ProductTransformation>(
            e.endpoint.products.transformation,
            data.value,
            {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
                }
            }).pipe(catchError(this.errors$.handleNetworkError));
    }


    save(data: Entity<ProductTransformation>): Observable<any> {

        return this._http.put<ProductTransformation>(
            e.endpoint.products.transformation,
            [data.value, data.original],
            {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
                }
            }).pipe(catchError(this.errors$.handleNetworkError));
    }


    delete(data: any): Observable<any> {

        return this._http.request('delete',
            e.endpoint.products.transformation,
            {
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
                }
            }).pipe(catchError(this.errors$.handleNetworkError));
    }



    private listRetrieved: BehaviorSubject<ProductTransformation[]> = new BehaviorSubject<ProductTransformation[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();
    getList(): Observable<ProductTransformation[]> {

        return this._http.get<ProductTransformation[]>(e.endpoint.products.transformation, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
            }
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    notifyRetrieved(data: ProductTransformation[]): void {
        this.listRetrieved.next(data);
    }

    getEntity: (key: any) => Observable<ProductTransformation>;

    getProductTransformationTypeList(): Observable<Enum[]> {

        return this._http.get<Enum[]>(e.endpoint.products.transformationTypes, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
            }
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

}
