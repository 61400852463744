import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as e } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { FleetList, Fleet, FleetManagementInterface } from './fleet-management.model';

@Injectable({
  providedIn: 'root'
})
export class FleetManagementService implements FleetManagementInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }
  private listRetrieved: BehaviorSubject<FleetList[]> = new BehaviorSubject<FleetList[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();

  save: (data: Entity<FleetList>) => Observable<any>;
  getEntity(param: any): Observable<any> {
    return this._http.get<Fleet>(e.endpoint.settings.fleet + "/" + param.Id, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  notifyRetrieved(data: FleetList[]): void {
    this.listRetrieved.next(data);
  }

  getList(): Observable<FleetList[]> {
    return this._http.get<FleetList[]>(e.endpoint.settings.fleet, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  create(data: Entity<any>): Observable<any> {
    return this._http.put<Fleet>(
      e.endpoint.settings.fleet,
      data.value,
      {
        headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(param: any): Observable<any> {
    this.header['responseType'] = 'text/plain';
    return this._http.delete(e.endpoint.settings.fleet + "/" + param.Id, {
      headers: this.header,
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

}




