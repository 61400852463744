import { Injectable } from '@angular/core';
import { Entity, Enum } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';

import { Assessment, AssessmentInterface, FormSection, SectionField } from './assessment.model';


import  cloneDeep from 'lodash/cloneDeep';
import { ErrorsService } from '@app/_shared/errors/errors.service';


@Injectable({
    providedIn: 'root'
})
export class AssessmentService implements AssessmentInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }

    create(data: Entity<Assessment>): Observable<any> {
        return this._http.post<Assessment>(
            e.endpoint.fleet.assessment,
            data.value,
            {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
                }
            }).pipe(catchError(this.errors$.handleNetworkError));
    }


    save(data: Entity<Assessment>): Observable<any> {

        let dataReduced: Assessment = cloneDeep(data.value);
        dataReduced.FormSections = [];

        data.value.FormSections.forEach((s, si) => {

            let sOrig = data.original.FormSections.find(so => s.id == so.id && s.formID == so.formID);

            if (sOrig == null) {
                dataReduced.FormSections.push(s);
            }
            else
                if (JSON.stringify(sOrig) !== JSON.stringify(s)) {

                    let sReduced: FormSection = cloneDeep(s);
                    sReduced.SectionFields = [];

                    s.SectionFields.forEach((f, fi) => {

                        let fOrig = sOrig.SectionFields.find(fo => f.id == fo.id && f.formID == fo.formID && f.sectionID == sOrig.id);

                        if (fOrig == null) {
                            sReduced.SectionFields.push(f);
                        }
                        else
                            if (JSON.stringify(fOrig) !== JSON.stringify(f)) {

                                let fReduced: SectionField = cloneDeep(f);
                                fReduced.FieldOptions = [];

                                f.FieldOptions.forEach((o, oi) => {

                                    let oOrig = fOrig.FieldOptions.find(oo => o.id == oo.id && o.formID == oo.formID && o.sectionID == sOrig.id && o.fieldID == fOrig.id);

                                    if (oOrig == null || JSON.stringify(oOrig) !== JSON.stringify(o)) {
                                        fReduced.FieldOptions.push(o);
                                    }

                                });
                                sReduced.SectionFields.push(fReduced);
                            }
                    });
                    dataReduced.FormSections.push(sReduced);
                }
        });


        return this._http.put<Assessment>(
            e.endpoint.fleet.assessment,
            dataReduced,
            {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
                }
            }).pipe(catchError(this.errors$.handleNetworkError));
    }


    delete(data: any): Observable<any> {

        return this._http.request('delete',
            e.endpoint.fleet.assessment,
            {
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
                }
            }).pipe(catchError(this.errors$.handleNetworkError));
    }


    private listRetrieved: BehaviorSubject<Assessment[]> = new BehaviorSubject<Assessment[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();
    getList(): Observable<Assessment[]> {

        return this._http.get<Assessment[]>(e.endpoint.fleet.assessment, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
            }
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    notifyRetrieved(data: Assessment[]): void {
        this.listRetrieved.next(data);
    }

    getEntity(key: any): Observable<Assessment> {

        return this._http.get<Assessment>(e.endpoint.fleet.assessment + '/' + key.FormId, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
            }
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getFieldTypeList(): Observable<Enum[]> {

        return this._http.get<Enum[]>(e.endpoint.fleet.assessmentFieldTypes, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
            }
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
}
