import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as e } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { vehicle, vehicleInterface } from './vehicle.component.model';

@Injectable({
    providedIn: 'root'
})
export class vehicleService implements vehicleInterface {

    constructor(private _http: HttpClient, private errors$: ErrorsService) { }

    header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }
    private listRetrieved: BehaviorSubject<vehicle[]> = new BehaviorSubject<vehicle[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();

    notifyRetrieved(data: vehicle[]): void {
        this.listRetrieved.next(data);
    }

    save: (data: Entity<vehicle>) => Observable<any>;

    create(data: Entity<vehicle>): Observable<any> {
        return this._http.put<vehicle>(
            e.endpoint.fleet.vehicle,
            data.value,
            {
                headers: this.header
            }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getList(): Observable<vehicle[]> {
        return this._http.get<vehicle[]>(e.endpoint.fleet.vehicle, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    delete(param: any): Observable<any> {
        this.header['responseType'] = 'text/plain';
        return this._http.delete(e.endpoint.fleet.vehicle + "/" + param.Id + "/" + param.DeviceId, {
            headers: this.header,
            responseType: "text"
        },).pipe(catchError(this.errors$.handleNetworkError));
    }

    getEntity(param) {
        return this._http.get<vehicle>(e.endpoint.fleet.vehicle + '/' + param.Id, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getVehicleBuildTypes() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/buildtypes', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getVehicleBoxConfig() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/boxconfig', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getVehicleClasses() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/vehicleclasses', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getNewVehicle() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/newvehicle', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getCompanyList() {
        return this._http.get<any>(e.endpoint.company + '/dropdown/1', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getNonPrintCompanyList() {
        return this._http.get<any>(e.endpoint.settings.nonPrintCompanyDropdown + '1', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getCompartmentSetList() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/compartmentsets', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getLoadPointList() {
        return this._http.get<any>(e.endpoint.loadPoint, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getDeviceTypeList() {
        return this._http.get<any>(e.endpoint.device + '/devicetypes', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getDeviceConfigList() {
        return this._http.get<any>(e.endpoint.device + '/deviceconfig', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getDeviceStateList() {
        return this._http.get<any>(e.endpoint.device + '/devicestate', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getDataAreaList() {
        return this._http.get<any>(e.endpoint.api + '/dataarea/list/0', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getTimeZones() {
        return this._http.get<any>(e.endpoint.masterData + '/timezones', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getTimeZoneValues() {
        return this._http.get<any>(e.endpoint.masterData + '/timezonesvalues', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getTrailerStates() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/trailerstate', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getVehicleTypes() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/vehicletypes/0', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    getSpecialActions() {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/specialactions', {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getVehicleDetails(param) {
        return this._http.get<any>(e.endpoint.fleet.vehicle + '/' + param.Id, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    getFilteredVehicleList(param) {
        return this._http.put<any>(e.endpoint.fleet.vehicle + '/filter', param, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    saveSpecialAction(vehiclelist, specialaction) {
        var param = { Vehicles: vehiclelist, SpecialActionId: specialaction };
        return this._http.put<any>(e.endpoint.fleet.vehicle + "/specialactions", param, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    deleteVehicleType(param) {
        this.header['responseType'] = 'text/plain';
        return this._http.delete(e.endpoint.fleet.vehicle + '/vehicletypes/' + param, {
            headers: this.header,
            responseType: "text"
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    saveVehicleTypes(vehicleTypes){
        return this._http.put<any>(e.endpoint.fleet.vehicle + "/vehicletypes",vehicleTypes, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    isCompartmentSetDeletable(param){
        return this._http.get<any>(e.endpoint.fleet.vehicle + "/compartmentsets/deletable/" + param, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    deleteCompartmentSet(param){
        this.header['responseType'] = 'text/plain';
        return this._http.delete(e.endpoint.fleet.vehicle + "/compartmentsets/" + param, {
            headers: this.header,
            responseType: "text"
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
    saveCompartmentSets(param){
        return this._http.put<any>(e.endpoint.fleet.vehicle + "/compartmentsets", param, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    saveMultipleVehicles(vehicle, vehiclelist){
        var data = { Vehicles: vehiclelist, Vehicle: vehicle };
        return this._http.put<any>(e.endpoint.fleet.vehicle + "/multiplevehicles", data, {
            headers: this.header
        }).pipe(catchError(this.errors$.handleNetworkError));
    }

    deleteCompartment(Id, setId){
        this.header['responseType'] = 'text/plain';
        return this._http.delete(e.endpoint.fleet.vehicle + "/compartmentsets/compartment/"  + setId + "/" + Id, {
            headers: this.header,
            responseType: "text"
        }).pipe(catchError(this.errors$.handleNetworkError));
    }
}
