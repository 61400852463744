import { Injectable } from '@angular/core';
import { Entity, Enum } from '@app/app.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';
import { ProductGroup, ProductGroupInterface } from './product-group.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
@Injectable({
  providedIn: 'root'
})
export class ProductGroupService implements ProductGroupInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }
  header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }
  private listRetrieved: BehaviorSubject<ProductGroup[]> = new BehaviorSubject<ProductGroup[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();
  notifyRetrieved(data: ProductGroup[]): void {
    this.listRetrieved.next(data);
  }

  save: (data: Entity<ProductGroup>) => Observable<any>;

  getList(): Observable<ProductGroup[]> {
    return this._http.get<ProductGroup[]>(e.endpoint.products.productGroup, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getEntity(param: any): Observable<ProductGroup> {
    return this._http.get<ProductGroup>(e.endpoint.products.productGroup + '/' + param, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  create(data: Entity<ProductGroup>): Observable<any> {
    return this._http.put<ProductGroup>(
      e.endpoint.products.productGroup,
      data.value,
      {
        headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(id: any): Observable<any> {
    this.header['responseType'] = 'text/plain';
    return this._http.delete(e.endpoint.products.productGroup + '/' + id, {
      headers: this.header,
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

}
