// This file can be replaced during build by using the `fileReplacements` array.

// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

// `ng serve -c dev` replaces `environment.ts` with `environment.dev.ts`.

// The list of file replacements can be found in `angular.json`.

const URL = getBaseURL();
const API = URL + '/api/';

function getBaseURL() {
  let { hostname, port, pathname } = window.location;    
  pathname = port == '4200' ? '/TouchStar.FleetAtlasCM.API' : pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
  var baseurl = '//'+hostname+pathname;
  console.log("hostname: " + hostname + " port: " + port + " pathname: " + pathname );
  return baseurl;
};

export const environment = {

  production: false,

  endpoint: {

    server: URL,
    api: API,

    company: API + 'company',
    masterData: API + 'masterdata',

    safetycheck: API + 'safetycheck',

    configuration: API + 'configuration',

    fleet: {
      devices: API + 'device',
      vehicles: API + 'vehicle',
      assessment: API + 'assessment',
      assessmentFieldTypes: API + 'assessment/field-types',
      manageImages: API + 'manage-images',
      manageImagesUploadURL: API + 'manage-images-upload',
      drivermanagement: API + 'driver',
      masterDataLanguage: API + 'masterdata/languages',
      additionalFields: API + 'AdditionalFields',
      timeLog: API + 'timelog',
      configurationGroup: API + 'configurationgroup',
      vehicle: API + 'vehicle',
      configuration: API + 'configuration',
	  configurationset: API + 'configurationset'
    },

    products: {
      product: API + 'product',
      blends: API + 'product-blends',
      transformation: API + 'product-transformation',
      transformationTypes: API + 'product-transformation/product-transformation-types',
      productCategory: API + 'product-category',
      priceChangeable: API + 'product/pricechangeable/dropdown',
      salesTax: API + 'product/salestax/dropdown',
      quantityUnit: API + 'product/quantityunit/dropdown',
      productType: API + 'product/producttype/dropdown',
      dangerousGoods: API + 'product/dangerousgoods/dropdown',
      productDropdown: API + 'product/dropdown/1',
      newproduct: API + 'product/newproduct',
      productTypes:API +'product/producttypes',
      productShapes:API + 'product-category/shapes',
      productCategoryDeletable:API + 'product-category/deletable',
      dataArea:API + 'dataarea',
      productGroup: API + 'productgroup',
      productDevice: API + 'deviceproduct',
      deviceProductGroup:API + 'deviceproductgroup',
      factor: API + 'factor',
      specialPriceGroup: API + 'specialpricegroup'
    },
    
    loadPoint: API + 'loadpoint',

    settings: {
      printTemplates: API + 'print-templates',
      taxRates: API + 'tax-rates',
      printTexts: API + 'print-texts',
      modules: API + 'modules',
      nonPrintCompanyDropdown: API + 'company' + '/nonprintcompanydropdown/',
      user: API +'user',
      auditLog: API + 'auditlog',
      paymentTerms: API + 'paymentterms',
      fleet: API + 'fleet',
      bankAccount: API + 'bankaccount'
    },
    userLoginInfo: API + 'masterdata/user-login-info',
    modulesStatus: API + 'modules/status',
    apiversion: API + 'login/apiversion',
    exportURL: API + 'export',
    ssoBaseURL: API + 'login/ssobaseurl',
    customers: {
      customer: API + 'customer',
      specialPriceGroup: API + 'specialpricegroup'
    },
    unloadPoint: API + 'unloadpoint',
    paymentTerms: API + 'paymentterms',
    strappingChartProfiles: API + 'strappingchartprofiles',
    device: API + 'device'
    
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
