import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginSSOData, LoginSSOInterface } from './login-sso.model';
import { AuthenticationService } from '@pdiplatform/authentication';

@Injectable({
  providedIn: 'root'
})
export class LoginSSOService implements LoginSSOInterface {

  authService:any;
  static ssoAuthenticationUrl: string = "";

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  authentincateSSO(param: LoginSSOData, ssotoken: string) {
    var data = 'grant_type=OktaAccessToken&company=' + param.company;
    return this._http.post(environment.endpoint.server + '/token', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + ssotoken
        },
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  InitializeAuthService() {
    if(!LoginSSOService.ssoAuthenticationUrl || !this.authService) {
      this._http.get(environment.endpoint.ssoBaseURL, { 
          headers: { 
            'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') } 
          }).subscribe(res => { 
            LoginSSOService.ssoAuthenticationUrl = 'https://' + res + '/';
            this.authService = new AuthenticationService(LoginSSOService.ssoAuthenticationUrl);
        }, (err => {
            console.log("Unable to fetch Authentication URL. No API Connection.");
      }));
    }
}
}