import { Component, OnInit, Input, Output, EventEmitter, ViewChild, DoCheck, OnChanges, SimpleChanges } from '@angular/core';
import { PrintTextReference, PrintTextReferenceTypeEng } from './print-text.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { DxDataGridComponent, DxDropDownBoxComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { AppService } from '@app/app.service';
import { PrintTextsService } from './print-text.service';
import { Enum } from '@app/app.model';
import { PrintTemplatesService } from '@settings/print-templates/print-templates.service';
import { SharedService } from '@app/_shared/shared.service';

import inRange from 'lodash/inRange';
import some from 'lodash/some';
import { displayZeroValueText, makePDIDropDownOptions } from '@app/common_helper';

@Component({
    selector: 'facnm-print-text-reference',
    templateUrl: './print-text-reference.component.html',
    styleUrls: ['./print-text-reference.component.scss']
})
export class PrintTextReferenceComponent implements OnInit, DoCheck, OnChanges {


    @Input() printTextReference: PrintTextReference;
    @Output() printTextReferenceChange: EventEmitter<PrintTextReference> = new EventEmitter<PrintTextReference>();

    @Input() invalid: boolean;
    @Output() invalidChange: EventEmitter<boolean> = new EventEmitter();

    typeName: string;
    @Input() printTextCreated: boolean;

    @ViewChild('dataGridReferenceTypeData') dataGrid: DxDataGridComponent;
    @ViewChild('dropDownBox_Reference') dropDownBox_Reference: DxDropDownBoxComponent;

    printTextReferenceDataStore: ArrayStore;
    printTextReferenceData: any[];

    printTextReferenceTypes: Enum[];
    printTemplateTypes: Enum[];
    vehicleTypes: any;

    currentReferenceDataKey: any;
    safetyGroups: Enum[];
    safetySummaryTypes: Enum[];
    emptyOption:any = {key:0};

    constructor(private printTexts$: PrintTextsService, private printTemplates$: PrintTemplatesService, private app$: AppService, private errors$: ErrorsService, private actions$: ActionsService, private shared$: SharedService) {

        this.actions$.EntityReady.subscribe(() => setTimeout(() => {
            if (this.currentReferenceDataKey) {
                this.dataGrid.instance.selectRows(this.currentReferenceDataKey, false);
                this.dataGrid.instance.navigateToRow([this.currentReferenceDataKey]);
            }
        }, 0));
    }

    ngOnInit() {
        if (this.printTextReference) {

            this.printTexts$.getPrintTextTypes().subscribe(t => this.printTextReferenceTypes = makePDIDropDownOptions(t,'id','name'));
            this.printTemplates$.getTemplatesTypes().subscribe(t => {
                    this.printTemplateTypes = makePDIDropDownOptions(t,'id','name');
                    this.printTemplateTypes.unshift(this.emptyOption);
                }
            );
            this.shared$.getVehicleTypes().subscribe(t => {
                this.vehicleTypes = makePDIDropDownOptions(t,'Id','Name');
                if(this.typeName == 'CompanyVehicleTypeId' && this.printTextReference.extID2 == 0)
                displayZeroValueText(this.vehicleTypes,'vehicleType','key','label');
            });
            this.shared$.getSafetyGroups().subscribe(t => this.safetyGroups = makePDIDropDownOptions(t,'id','name'));
            this.printTexts$.getSafetySummaryTypes().subscribe(t => {
                this.safetySummaryTypes = makePDIDropDownOptions(t,'id','name');
                if(this.typeName == 'SafetyCheck' && this.printTextReference.extID2 == 0)
                displayZeroValueText(this.safetySummaryTypes,'safetyTypes','key','label');
            });

            this.refreshReferenceData();
        }
    }

    ngDoCheck() {
        if (this.printTextReference) {
            this.invalid = some([
                this.printTextReference.NumberOfLines,
                this.printTextReference.TextType
            ], (input) => !inRange(input, 0, 255));

            this.invalidChange.emit(this.invalid);
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if (JSON.stringify(changes['printTextReference'].currentValue) !== JSON.stringify(changes['printTextReference'].previousValue)) {
            this.refreshReferenceData();
        }
    }

    onChange_ReferenceType($event) {

        this.refreshReferenceData();
    }

    onRowClick($clikedRow) {

        this.printTextReference.extID1 = $clikedRow.data.Id;
    }

    hasTemplate() {

        return ['Product', 'CustomerPrintType', 'CustomerTaxGroup', 'ProductTextAtConfigurablePosition', 'CompanyTemplateType'].includes(this.typeName);
    }


    hasReferenceData() {

        return ['CustomerPrintType', 'CustomerTaxGroup', 'SafetyCheck'].includes(this.typeName) == false;
    }

    onOpened($event) {

        setTimeout(() => {
            if (this.currentReferenceDataKey)
                this.dataGrid.instance.selectRows(this.currentReferenceDataKey, false);
        });
        if (this.currentReferenceDataKey)
            this.dataGrid.instance.navigateToRow([this.currentReferenceDataKey]);
    }

    isInvalidByteNumberInput(input) {

        return !input.dirty && input.value < 0 || input.value > 255;
    }

    allowOnlyNumbers(evt) {

        return this.app$.allowOnlyNumbers(evt);
    }

    private refreshReferenceData() {

        this.errors$.clearErrors();

        this.printTextReferenceDataStore = undefined;
        this.printTextReferenceData = undefined;

        this.typeName = PrintTextReferenceTypeEng[this.printTextReference.ReferenceType] || '';

        if (this.hasReferenceData()) {

            this.actions$.notifyEntityBusy();

            this.printTexts$.getPrintTextReferenceData(this.printTextReference).subscribe(data => {

                this.printTextReferenceData = data.printTextReferences;

                this.currentReferenceDataKey = this.printTextReference.extID1;

                this.printTextReferenceDataStore = new ArrayStore({
                    data: this.printTextReferenceData,
                    key: 'Id'
                });

                this.actions$.notifyEntityReady();

            }, (errorNetwork) => {
                this.errors$.handleServerErrors(errorNetwork);
            });
        }
    }

}
