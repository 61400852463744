import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as e } from 'src/environments/environment';
import { AuditLogInterface, EntityList, Log, filterOptions } from './audit-log.model';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService implements AuditLogInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }
  header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }

  getEntityList(): Observable<EntityList[]> {
    return this._http.get<EntityList[]>(e.endpoint.settings.auditLog + '/entitylist', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  getAuditLogList(filterOptions: filterOptions): Observable<Log[]> {
    return this._http.put<Log[]>(
      e.endpoint.settings.auditLog + '/filter',
      filterOptions,
      {
        headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }

}
