import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity } from '@app/app.model';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as e } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { PaymentTerm, PaymentTermInterface, PaymentTermList } from './payment-term.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentTermService implements PaymentTermInterface {

  constructor(private _http: HttpClient, private errors$: ErrorsService) { }

  header = { 'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer') }
  private listRetrieved: BehaviorSubject<PaymentTermList[]> = new BehaviorSubject<PaymentTermList[]>(undefined);
  ListRetrieved = this.listRetrieved.asObservable();

  save: (data: Entity<PaymentTerm>) => Observable<any>;
  getEntity(param: any) {
    return this._http.get<PaymentTerm>(e.endpoint.settings.paymentTerms + "/" + param.CompanyId + "/" + param.Id, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }

  notifyRetrieved(data: PaymentTermList[]): void {
    this.listRetrieved.next(data);
  }

  getCompanyList() {
    return this._http.get<PaymentTermList>(e.endpoint.company + '/dropdown/0', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }
  getPaymentTermOptionList() {
    return this._http.get<PaymentTermList>(e.endpoint.settings.paymentTerms + '/paymenttermoption', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }


  getList(companyId: number): Observable<PaymentTermList[]> {
    return this._http.get<PaymentTermList[]>(e.endpoint.settings.paymentTerms + "/paymentterms/" + companyId, {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }
  create(data: any): Observable<any> {
    return this._http.put<PaymentTermList>(
      e.endpoint.settings.paymentTerms + "/" + data.originalId,
      data.data.value,
      {
        headers: this.header
      }).pipe(catchError(this.errors$.handleNetworkError));
  }

  delete(param: any): Observable<any> {
    this.header['responseType'] = 'text/plain';
    return this._http.delete(e.endpoint.settings.paymentTerms + "/" + param.CompanyId + "/" + param.Id, {
      headers: this.header,
      responseType: "text"
    },).pipe(catchError(this.errors$.handleNetworkError));
  }

  getNewEntity() {
    return this._http.get<PaymentTerm>(e.endpoint.settings.paymentTerms + '/newpaymentterm', {
      headers: this.header
    }).pipe(catchError(this.errors$.handleNetworkError));
  }
}
