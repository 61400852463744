import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment as e } from 'src/environments/environment';

import { Product } from './products.model';

import { ErrorsService } from '@app/_shared/errors/errors.service';

@Injectable({
    providedIn: 'root',
})
export class ProductService {


    constructor(private _http: HttpClient, private errors$: ErrorsService) { }


    private listRetrieved: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>(undefined);
    ListRetrieved = this.listRetrieved.asObservable();

    getList(): Observable<Product[]> {

        return this._http.get<Product[]>(e.endpoint.products.product, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('Bearer')
            }
        }).pipe(catchError(this.errors$.handleNetworkError));
    }   


    notifyRetrieved(data: Product[]): void {
        this.listRetrieved.next(data);
    }

}
